    <div class="app-container" *ngIf="!isHMAOrMk3ContractorsUser" >
        <div class="loading-container" *ngIf="isLoading">
            <div class="lds-grid">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        <div class="portal-header">
            <h5 style="text-align: center;">Trades for {{this.refNo}}</h5>
        </div>
        <div class="content">
            <div fxLayout="row wrap" fxLayoutGap="12px grid" fxLayoutAlign="stretch">
                <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
                    <mat-card class="mat-card-heading">
                        <h6>Trade Summary</h6>
                    </mat-card>
                  </div>
                
                        <!-- Trades Table -->
                        <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%">
                        <mat-card>
                            <mat-card-content>
                                <table>
                                    <tr>
                                        <th class="table-header-cd">Invoices Received Total</th>
                                        <td>{{totalAmountOfInvoicesPaidToDate}}</td>
                                    </tr>
                                    <tr>
                                        <th class="table-header-cd">Open external work Instructions </th>
                                        <td>{{openExternalWorkInstructions}}</td>
                                    </tr>
                                </table>
                            </mat-card-content>
                        </mat-card>
                        </div>
                        <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%">
                        <mat-card>
                            <mat-card-content>
                                <table>
                                    <tr>
                                        <th class="table-header-cd">Total Quote Requests</th>
                                        <td>{{totalQuoteRequests}}</td>
                                    </tr>
                                    <tr>
                                        <th class="table-header-cd">Total Internal trade work instructions</th>
                                        <td>{{totalInternalTradeWorkInstructions}}</td>
                                    </tr>
                                </table>
                            </mat-card-content>
                        </mat-card>
                    </div>

                </div>
                
                <div class="centered-div">
                    <div class="mat-elevation-z8">
                        <!-- Trades Table -->
                        <mat-card class="mat-card-heading">
                            <h6>Instructions</h6>
                        </mat-card>
                        <mat-table class="trades-table" [dataSource]="dataSourceTrades" matSort matSortStart="asc">
                            <ng-container matColumnDef="jobId">
                                <th class="w-3" mat-header-cell *matHeaderCellDef mat-sort-header>Instruction ID</th>
                                <td mat-cell *matCellDef="let trades">{{trades.jobId}}</td>
                            </ng-container>

                            <ng-container matColumnDef="dateSent">
                                <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Date Sent</th>
                                <td mat-cell *matCellDef="let trades">{{trades.dateSent | date: 'dd/MM/yyyy'}}</td>
                            </ng-container>
                            <ng-container matColumnDef="tradingName">
                                <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Contractor</th>
                                <td mat-cell *matCellDef="let trades">{{trades.tradingName}}</td>
                            </ng-container>
                            <ng-container matColumnDef="isFinalInvoice">
                                <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Final Invoice</th>
                                <td mat-cell *matCellDef="let trades">
                                    {{trades.isFinalInvoice?'Yes':trades.isFinalInvoice==false?'No':''}}</td>
                            </ng-container>
                            <ng-container matColumnDef="invoiceNumber">
                                <th class="w-3" mat-header-cell *matHeaderCellDef mat-sort-header>Invoice Number</th>
                                <td mat-cell *matCellDef="let trades">{{trades.invoiceNumber}}</td>
                            </ng-container>
                            <ng-container matColumnDef="invoiceAmount">
                                <th class="w-2" mat-header-cell *matHeaderCellDef mat-sort-header>Invoice Amount</th>
                                <td mat-cell *matCellDef="let trades">{{trades.invoiceAmount}}</td>
                            </ng-container>
                            <ng-container matColumnDef="statusName">
                                <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Instruction Status
                                </th>
                                <td mat-cell *matCellDef="let trades">{{trades.statusName}}</td>
                            </ng-container>
                            <ng-container matColumnDef="actionedDate">
                                <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Actioned</th>
                                <td mat-cell *matCellDef="let trades">{{trades.actionedDate | date: 'dd/MM/yyyy'}}</td>
                            </ng-container>
                            <ng-container matColumnDef="instructionFinalise">
                                <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Instruction Finalised
                                </th>
                                <td mat-cell *matCellDef="let trades">{{trades.instructionFinalise}}</td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedTradesColumns"></tr>
                            <tr mat-row class="row-hover mat-row"
                                *matRowDef="let row; columns: displayedTradesColumns;"></tr>
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="9999">
                                    No Trades found for this claim
                                </td>
                            </tr>
                        </mat-table>
                        <div class="center-button-container" *ngIf="!isLoading">
                            <button mat-raised-button class="btn" (click)=onCancel()>Back</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
<div fxLayout="row wrap" *ngIf="isHMAOrMk3ContractorsUser">
    <div>
        <h4>You don't have access to this page!</h4>
    </div>
</div>