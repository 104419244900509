import { Component, OnInit } from '@angular/core';
//import { ChartOptions } from 'chart.js';

@Component({
  selector: 'app-management-dash',
  templateUrl: './management-dash.component.html',
  styleUrls: ['./management-dash.component.scss']
})
export class ManagementDashComponent implements OnInit {

    // Pie
    /*public pieChartOptions: ChartOptions<'pie'> = {
      responsive: false,
    };
    public pieChartLabels = [ [ 'Download', 'Sales' ], [ 'In', 'Store', 'Sales' ], 'Mail Sales' ];
    public pieChartDatasets = [ {
      data: [ 300, 500, 100 ]
    } ];
    public pieChartLegend = true;
    public pieChartPlugins = [];*/

  constructor() { }

  ngOnInit(): void {
  }

}
